@import '../styles/colors';

.project-tile {
    width: 30%;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    .project-body {
      width: 100%;
      height: 280px;
      //height: 100%;
      overflow: hidden;
      background-color: gray;
      img {
        width: 100%;
        transition: 0.5s ease;
      }
    }
    .project-title {
      box-sizing: border-box;
      display: flex;
      height: 50px;
      width: 100%;
      a {
        box-sizing: border-box;
        font-size: .8em;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $main-white;
        background-color: $black;
        transition: .5s ease;
        &:hover {
          //background-color: $nav-link-hover;
          color: orange;
          &::after {
            content: '->';
            padding-left: 10px;
          }
        }
      }    
    }
    &:hover {
      .project-body img {
        transform: scale(1.5, 1.5);
      }
      .project-title a {
          //background-color: $nav-link-hover;
          color: orange;
          &::after {
            content: '->';
            padding-left: 10px;
          }
      }
    }
  }