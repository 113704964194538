@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import 'styles/colors';


html {
  scroll-behavior: smooth;
}

body {
  margin: 0; 
  display: flex;
  flex-direction: column;
  background: $main-black;  
  color: $main-white;
  font-family: "Ubuntu Condensed", impact;
  font-size: calc(18px + (28 - 18) * ((100vw - 500px) / (1600 - 500)));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Ubuntu Mono", monospace;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

a {
  text-decoration: none;
  color: $main-white;  
  &:hover {
    color: orange;
  }
}

h1, h2, h3, nav {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  text-shadow: 2px 2px 4px rgb(10, 10, 10);
}


h1 {font-size: 2em;}
h2 {font-size: 1.6em;}
h3 {font-size: 1.2em;}